<template>
  <b-card class="form-card" v-if="entry">
    <div
      class="d-flex justify-content-between align-items-center accordion-header"
      role="tab"
    >
      <div>
        <p class="accordion-title">Informações adicionais da entrada</p>
      </div>
      <ChevronDown
        class="chevron"
        v-b-toggle.warehouse-accordion
      />
    </div>

    <b-collapse
      ref="accordion"
      id="warehouse-accordion"
      accordion="accordion-warehouse-entry"
      role="tabpanel"
      :visible="visible"
    >
      <div class="divider"></div>
      <b-row>
        <b-col cols="6" class="mb-1">
          <p><span class="info-title">Nota fiscal:</span> {{ entry.invoice_number ?? '-' }}</p>
        </b-col>
        <b-col cols="6" class="mb-1">
          <p><span class="info-title">Série:</span> {{ entry.serial_number ?? '-' }}</p>
        </b-col>
        <b-col cols="12" class="mb-1">
          <p><span class="info-title">Chave de acesso:</span> {{ entry.access_key ?? '-' }}</p>
        </b-col>
        <b-col cols="6" class="mb-1">
          <p><span class="info-title">Valor do frete:</span> {{ parseNumberToMoney(entry.delivery_value) }}</p>
        </b-col>
        <b-col cols="6" class="mb-1">
          <p><span class="info-title">Valor do seguro:</span> {{ parseNumberToMoney(entry.insurance_value) }}</p>
        </b-col>
        <b-col cols="6" class="mb-1">
          <p><span class="info-title">Outras despesas acess.:</span> {{ parseNumberToMoney(entry.other_expenses_value) }}</p>
        </b-col>
        <b-col cols="6" class="mb-1">
          <p><span class="info-title">Desconto:</span> {{ parseNumberToMoney(entry.discount) }}</p>
        </b-col>
        <b-col cols="6" class="mb-1">
          <p><span class="info-title">Valor total dos produtos:</span> {{ parseNumberToMoney(entry.total_value) }}</p>
        </b-col>
        <b-col cols="6" class="mb-1">
          <p><span class="info-title">Valor total da nota:</span> {{ parseNumberToMoney(entry.invoice_total_value) }}
          </p>
        </b-col>
        <b-col cols="12" class="mb-1">
          <p><span class="info-title">Observações:</span> {{ entry.observations ?? '-' }}</p>
        </b-col>
      </b-row>

    </b-collapse>
  </b-card>
</template>
<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  name: 'WarehouseEntryInfo',
  methods: { parseNumberToMoney },
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg')
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    entry() {
      return this.$store.getters['warehouse/getSelectedEntry']
    }
  }
}
</script>
<style lang="scss" scoped>
.form-card {
  background: var(--neutral-100);
  border-radius: 8px;
}

.accordion-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-blue);
  margin-bottom: 4px;
}

.divider {
  border-bottom: 1px solid var(--neutral-300);
  margin-top: 8px;
  margin-bottom: 8px;
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--type-active);

  .info-title {
    font-weight: 700;
  }
}

.chevron {
  width: 24px;
  height: 24px;
  stroke: var(--neutral-600);
  transition: all 0.5s;
  cursor: pointer;

  &.not-collapsed {
    animation: rotateUp 0.5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  &.collapsed {
    animation: rotateDown 0.5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    transform: rotate(180deg);
  }

  &.chevron:focus {
    outline: none !important;
  }
}
</style>